'use client'
import { styled } from '../../config'

function Svg({ active, ...rest }) {
  if (active) {
    return (
      <svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}>
        <circle cx="28" cy="28" r="28" fill="#E32D3F" />
        <g clipPath="url(#clip0_1304_26)">
          <circle cx="27.9996" cy="28.0006" r="25.2" fill="#C60B1E" />
          <path
            d="M13.0964 32L14.0161 34.8305H16.9923L14.5845 36.5799L15.5042 39.4104L13.0964 37.6611L10.6886 39.4104L11.6083 36.5799L9.20049 34.8305H12.1767L13.0964 32Z"
            fill="white"
          />
          <path
            d="M17.8969 38.7826L18.8166 41.6131H21.7928L19.385 43.3625L20.3047 46.193L17.8969 44.4437L15.4891 46.193L16.4088 43.3625L14.001 41.6131H16.9772L17.8969 38.7826Z"
            fill="white"
          />
          <path
            d="M27.4993 38.7826L28.419 41.6131H31.3952L28.9874 43.3625L29.9071 46.193L27.4993 44.4437L25.0915 46.193L26.0112 43.3625L23.6034 41.6131H26.5796L27.4993 38.7826Z"
            fill="white"
          />
          <path
            d="M37.1017 38.7826L38.0214 41.6131H40.9976L38.5898 43.3625L39.5095 46.193L37.1017 44.4437L34.6939 46.193L35.6136 43.3625L33.2058 41.6131H36.182L37.1017 38.7826Z"
            fill="white"
          />
          <path
            d="M22.6988 32L23.6185 34.8305H26.5947L24.1869 36.5799L25.1066 39.4104L22.6988 37.6611L20.291 39.4104L21.2107 36.5799L18.8029 34.8305H21.7791L22.6988 32Z"
            fill="white"
          />
          <path
            d="M32.3012 32L33.2209 34.8305H36.1971L33.7893 36.5799L34.709 39.4104L32.3012 37.6611L29.8934 39.4104L30.8131 36.5799L28.4053 34.8305H31.3815L32.3012 32Z"
            fill="white"
          />
          <path
            d="M41.9036 32L42.8233 34.8305H45.7995L43.3917 36.5799L44.3114 39.4104L41.9036 37.6611L39.4958 39.4104L40.4155 36.5799L38.0077 34.8305H40.9839L41.9036 32Z"
            fill="white"
          />
          <path
            d="M13.914 29H17.996V12.126H14.486C14.408 12.568 14.252 12.984 13.992 13.348C13.732 13.738 13.42 14.076 13.03 14.362C12.614 14.648 12.146 14.882 11.626 15.038C11.08 15.22 10.508 15.298 9.884 15.298V18.028H13.914V29ZM24.7558 29H28.8378V12.126H25.3278C25.2498 12.568 25.0938 12.984 24.8338 13.348C24.5738 13.738 24.2618 14.076 23.8718 14.362C23.4558 14.648 22.9878 14.882 22.4678 15.038C21.9218 15.22 21.3498 15.298 20.7258 15.298V18.028H24.7558V29ZM32.232 18.86L36.08 18.886C35.976 18.574 35.924 18.21 35.924 17.846C35.924 17.144 36.106 16.546 36.496 16.078C36.886 15.61 37.484 15.35 38.29 15.35C39.044 15.35 39.616 15.558 39.98 15.948C40.344 16.364 40.552 16.832 40.552 17.352C40.552 17.924 40.396 18.392 40.11 18.782C39.824 19.172 39.356 19.562 38.706 19.952L35.95 21.564C35.17 22.032 34.52 22.526 34 23.046C33.48 23.592 33.09 24.164 32.778 24.788C32.466 25.412 32.258 26.088 32.154 26.79C32.024 27.492 31.972 28.22 31.972 29H44.79V25.568H36.99C37.094 25.334 37.25 25.126 37.458 24.944C37.666 24.762 37.874 24.58 38.134 24.424L41.332 22.526C42.424 21.902 43.23 21.122 43.802 20.238C44.374 19.354 44.66 18.366 44.66 17.274C44.66 16.546 44.504 15.818 44.218 15.142C43.932 14.466 43.516 13.894 42.97 13.374C42.424 12.88 41.774 12.49 40.994 12.178C40.214 11.892 39.356 11.736 38.394 11.736C37.354 11.736 36.47 11.892 35.69 12.204C34.91 12.516 34.234 12.958 33.714 13.478C33.168 13.998 32.752 14.622 32.492 15.298C32.206 16 32.076 16.728 32.076 17.456C32.076 17.716 32.076 18.002 32.128 18.288C32.154 18.574 32.18 18.756 32.232 18.86Z"
            fill="white"
          />
        </g>
        <rect
          x="3.14883"
          y="3.15078"
          width="49.7"
          height="49.7"
          rx="24.85"
          stroke="url(#paint0_linear_1304_26)"
          strokeWidth="0.7"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1304_26"
            x1="27.9988"
            y1="2.80078"
            x2="27.9988"
            y2="53.2008"
            gradientUnits="userSpaceOnUse">
            <stop stopOpacity="1" />
            <stop offset="1" stopColor="#960917" stopOpacity="1" />
          </linearGradient>
          <clipPath id="clip0_1304_26">
            <rect
              x="2.79883"
              y="2.80078"
              width="50.4"
              height="50.4"
              rx="25.2"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>
    )
  }

  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}>
      <circle cx="28" cy="28" r="27.5" fill="white" stroke="#F1F1F1" />
      <g clipPath="url(#clip0_1304_14)">
        <circle
          cx="27.9996"
          cy="28.0006"
          r="25.2"
          fill="#C60B1E"
          fillOpacity="0.05"
        />
        <path
          d="M13.0964 32L14.0161 34.8305H16.9923L14.5845 36.5799L15.5042 39.4104L13.0964 37.6611L10.6886 39.4104L11.6083 36.5799L9.20049 34.8305H12.1767L13.0964 32Z"
          fill="#C60B1E"
        />
        <path
          d="M17.8969 38.7826L18.8166 41.6131H21.7928L19.385 43.3625L20.3047 46.193L17.8969 44.4437L15.4891 46.193L16.4088 43.3625L14.001 41.6131H16.9772L17.8969 38.7826Z"
          fill="#C60B1E"
        />
        <path
          d="M27.4993 38.7826L28.419 41.6131H31.3952L28.9874 43.3625L29.9071 46.193L27.4993 44.4437L25.0915 46.193L26.0112 43.3625L23.6034 41.6131H26.5796L27.4993 38.7826Z"
          fill="#C60B1E"
        />
        <path
          d="M37.1017 38.7826L38.0214 41.6131H40.9976L38.5898 43.3625L39.5095 46.193L37.1017 44.4437L34.6939 46.193L35.6136 43.3625L33.2058 41.6131H36.182L37.1017 38.7826Z"
          fill="#C60B1E"
        />
        <path
          d="M22.6988 32L23.6185 34.8305H26.5947L24.1869 36.5799L25.1066 39.4104L22.6988 37.6611L20.291 39.4104L21.2107 36.5799L18.8029 34.8305H21.7791L22.6988 32Z"
          fill="#C60B1E"
        />
        <path
          d="M32.3012 32L33.2209 34.8305H36.1971L33.7893 36.5799L34.709 39.4104L32.3012 37.6611L29.8934 39.4104L30.8131 36.5799L28.4053 34.8305H31.3815L32.3012 32Z"
          fill="#C60B1E"
        />
        <path
          d="M41.9036 32L42.8233 34.8305H45.7995L43.3917 36.5799L44.3114 39.4104L41.9036 37.6611L39.4958 39.4104L40.4155 36.5799L38.0077 34.8305H40.9839L41.9036 32Z"
          fill="#C60B1E"
        />
        <path
          d="M13.914 29H17.996V12.126H14.486C14.408 12.568 14.252 12.984 13.992 13.348C13.732 13.738 13.42 14.076 13.03 14.362C12.614 14.648 12.146 14.882 11.626 15.038C11.08 15.22 10.508 15.298 9.884 15.298V18.028H13.914V29ZM24.7558 29H28.8378V12.126H25.3278C25.2498 12.568 25.0938 12.984 24.8338 13.348C24.5738 13.738 24.2618 14.076 23.8718 14.362C23.4558 14.648 22.9878 14.882 22.4678 15.038C21.9218 15.22 21.3498 15.298 20.7258 15.298V18.028H24.7558V29ZM32.232 18.86L36.08 18.886C35.976 18.574 35.924 18.21 35.924 17.846C35.924 17.144 36.106 16.546 36.496 16.078C36.886 15.61 37.484 15.35 38.29 15.35C39.044 15.35 39.616 15.558 39.98 15.948C40.344 16.364 40.552 16.832 40.552 17.352C40.552 17.924 40.396 18.392 40.11 18.782C39.824 19.172 39.356 19.562 38.706 19.952L35.95 21.564C35.17 22.032 34.52 22.526 34 23.046C33.48 23.592 33.09 24.164 32.778 24.788C32.466 25.412 32.258 26.088 32.154 26.79C32.024 27.492 31.972 28.22 31.972 29H44.79V25.568H36.99C37.094 25.334 37.25 25.126 37.458 24.944C37.666 24.762 37.874 24.58 38.134 24.424L41.332 22.526C42.424 21.902 43.23 21.122 43.802 20.238C44.374 19.354 44.66 18.366 44.66 17.274C44.66 16.546 44.504 15.818 44.218 15.142C43.932 14.466 43.516 13.894 42.97 13.374C42.424 12.88 41.774 12.49 40.994 12.178C40.214 11.892 39.356 11.736 38.394 11.736C37.354 11.736 36.47 11.892 35.69 12.204C34.91 12.516 34.234 12.958 33.714 13.478C33.168 13.998 32.752 14.622 32.492 15.298C32.206 16 32.076 16.728 32.076 17.456C32.076 17.716 32.076 18.002 32.128 18.288C32.154 18.574 32.18 18.756 32.232 18.86Z"
          fill="#C60B1E"
        />
      </g>
      <rect
        x="3.14883"
        y="3.15078"
        width="49.7"
        height="49.7"
        rx="24.85"
        stroke="url(#paint0_linear_1304_14)"
        strokeWidth="0.7"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1304_14"
          x1="27.9988"
          y1="2.80078"
          x2="27.9988"
          y2="53.2008"
          gradientUnits="userSpaceOnUse">
          <stop stopOpacity="1" />
          <stop offset="1" stopOpacity="1" />
        </linearGradient>
        <clipPath id="clip0_1304_14">
          <rect
            x="2.79883"
            y="2.80078"
            width="50.4"
            height="50.4"
            rx="25.2"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export const BomberoCecop = styled(Svg)
