'use client'
import { styled } from '../../config'

function Svg({ active, ...rest }) {
  if (active) {
    return (
      <svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}>
        <circle cx="28" cy="28" r="28" fill="#36A0E1" />
        <g clipPath="url(#clip0_2753_6209)">
          <circle cx="27.9996" cy="28.0005" r="25.2" fill="#36A0E1" />
        </g>
        <rect
          x="3.14883"
          y="3.15078"
          width="49.7"
          height="49.7"
          rx="24.85"
          stroke="url(#paint0_linear_2753_6209)"
          strokeWidth="0.7"
        />
        <path
          d="M12.523 35V19.7273H22.4563V22.0465H15.2897V26.1928H21.9417V28.5121H15.2897V32.6808H22.5159V35H12.523ZM27.9406 19.7273V35H25.1739V19.7273H27.9406ZM30.8316 35V19.7273H36.5588C37.7321 19.7273 38.7165 19.9311 39.512 20.3388C40.3124 20.7464 40.9164 21.3182 41.3241 22.054C41.7368 22.7848 41.9431 23.6374 41.9431 24.6119C41.9431 25.5913 41.7343 26.4414 41.3167 27.1623C40.904 27.8782 40.295 28.4325 39.4896 28.8253C38.6842 29.2131 37.6948 29.407 36.5216 29.407H32.4424V27.1101H36.1487C36.8348 27.1101 37.3966 27.0156 37.8341 26.8267C38.2716 26.6328 38.5947 26.3519 38.8035 25.984C39.0173 25.6112 39.1242 25.1538 39.1242 24.6119C39.1242 24.07 39.0173 23.6076 38.8035 23.2248C38.5897 22.837 38.2641 22.5437 37.8266 22.3448C37.3891 22.141 36.8248 22.0391 36.1338 22.0391H33.5983V35H30.8316ZM38.7215 28.0795L42.5024 35H39.415L35.7012 28.0795H38.7215Z"
          fill="white"
        />
        <defs>
          <linearGradient
            id="paint0_linear_2753_6209"
            x1="27.9988"
            y1="2.80078"
            x2="27.9988"
            y2="53.2008"
            gradientUnits="userSpaceOnUse">
            <stop stopOpacity="1" />
            <stop offset="1" stopOpacity="1" />
          </linearGradient>
          <clipPath id="clip0_2753_6209">
            <rect
              x="2.79883"
              y="2.80078"
              width="50.4"
              height="50.4"
              rx="25.2"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>
    )
  }
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}>
      <g>
        <circle cx="28" cy="28" r="28" fill="#36A0E1" />
        <g clipPath="url(#clip0_2753_6215)">
          <circle cx="27.9996" cy="28.0005" r="25.2" fill="#36A0E1" />
        </g>
        <rect
          x="3.14883"
          y="3.15078"
          width="49.7"
          height="49.7"
          rx="24.85"
          stroke="url(#paint0_linear_2753_6215)"
          strokeWidth="0.7"
        />
        <path
          d="M12.523 35V19.7273H22.4563V22.0465H15.2897V26.1928H21.9417V28.5121H15.2897V32.6808H22.5159V35H12.523ZM27.9406 19.7273V35H25.1739V19.7273H27.9406ZM30.8316 35V19.7273H36.5588C37.7321 19.7273 38.7165 19.9311 39.512 20.3388C40.3124 20.7464 40.9164 21.3182 41.3241 22.054C41.7368 22.7848 41.9431 23.6374 41.9431 24.6119C41.9431 25.5913 41.7343 26.4414 41.3167 27.1623C40.904 27.8782 40.295 28.4325 39.4896 28.8253C38.6842 29.2131 37.6948 29.407 36.5216 29.407H32.4424V27.1101H36.1487C36.8348 27.1101 37.3966 27.0156 37.8341 26.8267C38.2716 26.6328 38.5947 26.3519 38.8035 25.984C39.0173 25.6112 39.1242 25.1538 39.1242 24.6119C39.1242 24.07 39.0173 23.6076 38.8035 23.2248C38.5897 22.837 38.2641 22.5437 37.8266 22.3448C37.3891 22.141 36.8248 22.0391 36.1338 22.0391H33.5983V35H30.8316ZM38.7215 28.0795L42.5024 35H39.415L35.7012 28.0795H38.7215Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2753_6215"
          x1="27.9988"
          y1="2.80078"
          x2="27.9988"
          y2="53.2008"
          gradientUnits="userSpaceOnUse">
          <stop stopOpacity="1" />
          <stop offset="1" stopOpacity="1" />
        </linearGradient>
        <clipPath id="clip0_2753_6215">
          <rect
            x="2.79883"
            y="2.80078"
            width="50.4"
            height="50.4"
            rx="25.2"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export const Eir = styled(Svg)
