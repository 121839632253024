'use client'
import { styled } from '../../config'
import clsx from 'clsx'

function Image({ active, ...rest }) {
  return (
    <div className={clsx(!active && 'opacity-50')}>
      <img
        src={'images/opo/aux_corp_locales.png'}
        alt="Corporaciones Locales"
        width={56}
        height={56}
        {...rest}
      />
    </div>
  )
}

export const AdministrativoCorporacionesLocales = styled(Image, { opacity: 1 })
