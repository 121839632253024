'use client'
import { styled } from '../../config'

function Svg({ active, ...rest }) {
  if (active) {
    return (
      <svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}>
        <circle cx="28" cy="28" r="28" fill="#F4F4F4" />
        <g clipPath="url(#clip0_1059_5084)">
          <circle cx="28" cy="28.001" r="25.2" fill="#EEEEEE" />
          <g filter="url(#filter0_d_1059_5084)">
            <rect
              x="9.79999"
              y="16.801"
              width="37.1"
              height="37.8"
              rx="2.1"
              fill="white"
            />
            <rect
              x="10.15"
              y="17.151"
              width="36.4"
              height="37.1"
              rx="1.75"
              stroke="url(#paint0_linear_1059_5084)"
              strokeWidth="0.7"
            />
          </g>
          <path
            d="M40.6 29.821L37.8 30.801V25.901H43.4V30.801L40.6 29.821Z"
            fill="#F0F0F0"
          />
          <rect
            x="11.9"
            y="34.301"
            width="2.8"
            height="2.8"
            rx="0.7"
            fill="#E1E1E1"
          />
          <rect
            x="11.9"
            y="21.7009"
            width="19.6"
            height="2.8"
            rx="0.7"
            fill="#E1E1E1"
          />
          <rect
            x="11.9"
            y="25.901"
            width="13.3"
            height="2.8"
            rx="0.7"
            fill="#E1E1E1"
          />
          <rect
            x="16.8"
            y="34.301"
            width="24.5"
            height="2.8"
            rx="0.7"
            fill="#F0F0F0"
          />
          <rect
            x="16.8"
            y="38.501"
            width="13.3"
            height="2.8"
            rx="0.7"
            fill="#F0F0F0"
          />
          <rect
            x="16.8"
            y="42.7009"
            width="20.3"
            height="2.8"
            rx="0.7"
            fill="#F0F0F0"
          />
          <rect
            x="11.9"
            y="38.501"
            width="2.8"
            height="2.8"
            rx="0.7"
            fill="#E1E1E1"
          />
          <rect
            x="11.9"
            y="42.7009"
            width="2.8"
            height="2.8"
            rx="0.7"
            fill="#E1E1E1"
          />
          <g filter="url(#filter1_d_1059_5084)">
            <circle cx="40.6" cy="23.101" r="4.2" fill="#F0F0F0" />
          </g>
          <circle cx="40.6" cy="23.101" r="2.8" fill="#E1E1E1" />
        </g>
        <rect
          x="3.15078"
          y="3.15078"
          width="49.7"
          height="49.7"
          rx="24.85"
          stroke="url(#paint1_linear_1059_5084)"
          strokeWidth="0.7"
        />
        <circle cx="28" cy="28" r="28" fill="#012169" />
        <g clipPath="url(#clip1_1059_5084)">
          <g clipPath="url(#clip2_1059_5084)">
            <path
              d="M-14.4108 3.54199H69.4107V53.4581H-14.4108V3.54199Z"
              fill="#012169"
            />
            <path
              d="M-4.64044 3.54201L-14.4108 3.54201L-14.4108 9.36649L59.6413 53.4585L69.4107 53.4585L69.4107 47.6332L-4.64044 3.54201Z"
              fill="white"
            />
            <path
              d="M69.4107 9.36656L69.4107 3.54207L59.6404 3.54207L-14.4108 47.6333L-14.4108 53.4586L-4.64131 53.4586L69.4107 9.36656Z"
              fill="white"
            />
            <path
              d="M19.1178 3.54199H35.8821V53.4581H19.1178V3.54199Z"
              fill="white"
            />
            <path
              d="M-14.4108 20.1808H69.4107V36.8195H-14.4108V20.1808Z"
              fill="white"
            />
            <path
              d="M22.4706 3.54199H32.5292V53.4581H22.4706V3.54199Z"
              fill="#C8102E"
            />
            <path
              d="M-14.4108 23.5085H69.4107V33.4918H-14.4108V23.5085Z"
              fill="#C8102E"
            />
            <path
              d="M69.4108 53.4585V49.5772L47.9824 36.8191H41.4651L69.4108 53.4585Z"
              fill="#C8102E"
            />
            <path
              d="M-14.4108 3.54199L-14.4108 7.4224L7.01676 20.1806L13.5332 20.1806L-14.4108 3.54199Z"
              fill="#C8102E"
            />
            <path
              d="M35.8824 20.1806H41.4728L69.4107 3.54199H62.9003L35.8824 19.6294V20.1806Z"
              fill="#C8102E"
            />
            <path
              d="M19.1175 36.8191H13.5288L-14.4039 53.4499V53.4585H-7.89955L19.1175 37.372V36.8191Z"
              fill="#C8102E"
            />
          </g>
        </g>
        <rect
          x="3.14883"
          y="3.15078"
          width="49.7"
          height="49.7"
          rx="24.85"
          stroke="url(#paint2_linear_1059_5084)"
          strokeOpacity="0.5"
          strokeWidth="0.7"
        />
        <defs>
          <filter
            id="filter0_d_1059_5084"
            x="6.29999"
            y="16.101"
            width="44.1"
            height="44.8"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2.8" />
            <feGaussianBlur stdDeviation="1.75" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_1059_5084"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_1059_5084"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_1059_5084"
            x="33.6"
            y="17.501"
            width="14"
            height="14"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1.4" />
            <feGaussianBlur stdDeviation="1.4" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_1059_5084"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_1059_5084"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_1059_5084"
            x1="28.35"
            y1="16.801"
            x2="28.35"
            y2="54.601"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="white" stopOpacity="1" />
            <stop offset="1" stopColor="white" stopOpacity="1" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_1059_5084"
            x1="28.0008"
            y1="2.80078"
            x2="28.0008"
            y2="53.2008"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#DADADA" />
            <stop offset="1" stopColor="#CDCDCD" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_1059_5084"
            x1="27.9988"
            y1="2.80078"
            x2="27.9988"
            y2="53.2008"
            gradientUnits="userSpaceOnUse">
            <stop stopOpacity="1" />
            <stop offset="1" stopOpacity="1" />
          </linearGradient>
          <clipPath id="clip0_1059_5084">
            <rect
              x="2.80078"
              y="2.80078"
              width="50.4"
              height="50.4"
              rx="25.2"
              fill="white"
            />
          </clipPath>
          <clipPath id="clip1_1059_5084">
            <rect
              x="2.79883"
              y="2.80078"
              width="50.4"
              height="50.4"
              rx="25.2"
              fill="white"
            />
          </clipPath>
          <clipPath id="clip2_1059_5084">
            <rect
              width="89"
              height="53"
              fill="white"
              transform="translate(-17 1)"
            />
          </clipPath>
        </defs>
      </svg>
    )
  }
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}>
      <circle cx="28" cy="28" r="28" fill="#F4F4F4" />
      <g clipPath="url(#clip0_1066_5250)">
        <circle cx="28" cy="28.001" r="25.2" fill="#EEEEEE" />
        <g filter="url(#filter0_d_1066_5250)">
          <rect
            x="9.79999"
            y="16.801"
            width="37.1"
            height="37.8"
            rx="2.1"
            fill="white"
          />
          <rect
            x="10.15"
            y="17.151"
            width="36.4"
            height="37.1"
            rx="1.75"
            stroke="url(#paint0_linear_1066_5250)"
            strokeWidth="0.7"
          />
        </g>
        <path
          d="M40.6 29.821L37.8 30.801V25.901H43.4V30.801L40.6 29.821Z"
          fill="#F0F0F0"
        />
        <rect
          x="11.9"
          y="34.301"
          width="2.8"
          height="2.8"
          rx="0.7"
          fill="#E1E1E1"
        />
        <rect
          x="11.9"
          y="21.7009"
          width="19.6"
          height="2.8"
          rx="0.7"
          fill="#E1E1E1"
        />
        <rect
          x="11.9"
          y="25.901"
          width="13.3"
          height="2.8"
          rx="0.7"
          fill="#E1E1E1"
        />
        <rect
          x="16.8"
          y="34.301"
          width="24.5"
          height="2.8"
          rx="0.7"
          fill="#F0F0F0"
        />
        <rect
          x="16.8"
          y="38.501"
          width="13.3"
          height="2.8"
          rx="0.7"
          fill="#F0F0F0"
        />
        <rect
          x="16.8"
          y="42.7009"
          width="20.3"
          height="2.8"
          rx="0.7"
          fill="#F0F0F0"
        />
        <rect
          x="11.9"
          y="38.501"
          width="2.8"
          height="2.8"
          rx="0.7"
          fill="#E1E1E1"
        />
        <rect
          x="11.9"
          y="42.7009"
          width="2.8"
          height="2.8"
          rx="0.7"
          fill="#E1E1E1"
        />
        <g filter="url(#filter1_d_1066_5250)">
          <circle cx="40.6" cy="23.101" r="4.2" fill="#F0F0F0" />
        </g>
        <circle cx="40.6" cy="23.101" r="2.8" fill="#E1E1E1" />
      </g>
      <rect
        x="3.15078"
        y="3.15078"
        width="49.7"
        height="49.7"
        rx="24.85"
        stroke="url(#paint1_linear_1066_5250)"
        strokeWidth="0.7"
      />
      <circle cx="28" cy="28" r="28" fill="white" />
      <g>
        <g clipPath="url(#clip1_1066_5250)">
          <g clipPath="url(#clip2_1066_5250)">
            <path
              d="M-14.4108 3.54199H69.4107V53.4581H-14.4108V3.54199Z"
              fill="#012169"
            />
            <path
              d="M-4.64044 3.54201L-14.4108 3.54201L-14.4108 9.36649L59.6413 53.4585L69.4107 53.4585L69.4107 47.6332L-4.64044 3.54201Z"
              fill="white"
            />
            <path
              d="M69.4107 9.36656L69.4107 3.54207L59.6404 3.54207L-14.4108 47.6333L-14.4108 53.4586L-4.64131 53.4586L69.4107 9.36656Z"
              fill="white"
            />
            <path
              d="M19.1178 3.54199H35.8821V53.4581H19.1178V3.54199Z"
              fill="white"
            />
            <path
              d="M-14.4108 20.1808H69.4107V36.8195H-14.4108V20.1808Z"
              fill="white"
            />
            <path
              d="M22.4706 3.54199H32.5292V53.4581H22.4706V3.54199Z"
              fill="#C8102E"
            />
            <path
              d="M-14.4108 23.5085H69.4107V33.4918H-14.4108V23.5085Z"
              fill="#C8102E"
            />
            <path
              d="M69.4108 53.4585V49.5772L47.9824 36.8191H41.4651L69.4108 53.4585Z"
              fill="#C8102E"
            />
            <path
              d="M-14.4108 3.54199L-14.4108 7.4224L7.01676 20.1806L13.5332 20.1806L-14.4108 3.54199Z"
              fill="#C8102E"
            />
            <path
              d="M35.8824 20.1806H41.4728L69.4107 3.54199H62.9003L35.8824 19.6294V20.1806Z"
              fill="#C8102E"
            />
            <path
              d="M19.1175 36.8191H13.5288L-14.4039 53.4499V53.4585H-7.89955L19.1175 37.372V36.8191Z"
              fill="#C8102E"
            />
          </g>
        </g>
        <rect
          x="3.14883"
          y="3.15078"
          width="49.7"
          height="49.7"
          rx="24.85"
          stroke="url(#paint2_linear_1066_5250)"
          strokeOpacity="0.5"
          strokeWidth="0.7"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1066_5250"
          x="6.29999"
          y="16.101"
          width="44.1"
          height="44.8"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2.8" />
          <feGaussianBlur stdDeviation="1.75" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1066_5250"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1066_5250"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_1066_5250"
          x="33.6"
          y="17.501"
          width="14"
          height="14"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.4" />
          <feGaussianBlur stdDeviation="1.4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1066_5250"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1066_5250"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1066_5250"
          x1="28.35"
          y1="16.801"
          x2="28.35"
          y2="54.601"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="1" />
          <stop offset="1" stopColor="white" stopOpacity="1" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1066_5250"
          x1="28.0008"
          y1="2.80078"
          x2="28.0008"
          y2="53.2008"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#DADADA" />
          <stop offset="1" stopColor="#CDCDCD" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1066_5250"
          x1="27.9988"
          y1="2.80078"
          x2="27.9988"
          y2="53.2008"
          gradientUnits="userSpaceOnUse">
          <stop stopOpacity="1" />
          <stop offset="1" stopOpacity="1" />
        </linearGradient>
        <clipPath id="clip0_1066_5250">
          <rect
            x="2.80078"
            y="2.80078"
            width="50.4"
            height="50.4"
            rx="25.2"
            fill="white"
          />
        </clipPath>
        <clipPath id="clip1_1066_5250">
          <rect
            x="2.79883"
            y="2.80078"
            width="50.4"
            height="50.4"
            rx="25.2"
            fill="white"
          />
        </clipPath>
        <clipPath id="clip2_1066_5250">
          <rect
            width="89"
            height="53"
            fill="white"
            transform="translate(-17 1)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export const EnglishFcs = styled(Svg)
