'use client'
import { styled } from '../../config'

function Svg({ active, ...rest }) {
  if (active) {
    return (
      <svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}>
        <circle cx="28" cy="28" r="28" fill="#F4F4F4" />

        <rect
          x="3.15078"
          y="3.15078"
          width="49.7"
          height="49.7"
          rx="24.85"
          stroke="url(#paint1_linear_5_55)"
          strokeWidth="0.7"
        />
        <circle cx="28" cy="28" r="28" fill="#9FE300" />
        <g clipPath="url(#clip1_5_55)">
          <circle cx="27.9988" cy="28.0008" r="25.2" fill="#90CE00" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M31.1632 15.8545C28.3813 21.1828 23.4323 25.4605 16.8449 28.2307C16.1881 28.5069 15.6484 28.7122 14.2254 29.2268L14 29.3083L14.011 29.5682C14.0321 30.0682 13.8268 30.0123 15.746 30.0401C16.7488 30.0547 17.7344 30.0934 18.1919 30.1363C21.8359 30.4782 26.0212 31.573 29.5506 33.1077C30.3966 33.4755 32.2286 34.3897 33.0663 34.8621C35.4039 36.18 37.8486 37.8773 39.9693 39.6545C40.3148 39.9441 40.4073 40 40.5409 40C40.675 40 40.7266 39.9685 40.865 39.8025C40.9684 39.6783 41.0167 39.5836 40.9948 39.5476C40.9757 39.5161 40.8049 39.3008 40.6152 39.0692C40.4255 38.8377 40.0269 38.3291 39.7293 37.939C34.8612 31.559 32.388 25.2438 32.1961 18.7034C32.1686 17.7674 32.192 15.9236 32.2386 15.3577C32.2546 15.1646 32.2505 15.1574 32.0972 15.1088C32.0105 15.0813 31.8652 15.0455 31.7744 15.0294L31.6094 15L31.1632 15.8545Z"
            fill="white"
          />
        </g>
        <rect
          x="3.14883"
          y="3.15078"
          width="49.7"
          height="49.7"
          rx="24.85"
          stroke="url(#paint2_linear_5_55)"
          strokeWidth="0.7"
        />
        <defs>
          <filter
            id="filter0_d_5_55"
            x="6.30078"
            y="16.1008"
            width="44.0996"
            height="44.8008"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2.8" />
            <feGaussianBlur stdDeviation="1.75" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_5_55"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_5_55"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_5_55"
            x="33.6004"
            y="17.5004"
            width="14.0004"
            height="14.0004"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1.4" />
            <feGaussianBlur stdDeviation="1.4" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_5_55"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_5_55"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_5_55"
            x1="28.3508"
            y1="16.8008"
            x2="28.3508"
            y2="54.6008"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="white" stopOpacity="1" />
            <stop offset="1" stopColor="white" stopOpacity="1" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_5_55"
            x1="28.0008"
            y1="2.80078"
            x2="28.0008"
            y2="53.2008"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#DADADA" />
            <stop offset="1" stopColor="#CDCDCD" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_5_55"
            x1="27.9988"
            y1="2.80078"
            x2="27.9988"
            y2="53.2008"
            gradientUnits="userSpaceOnUse">
            <stop stopOpacity="1" />
            <stop offset="1" stopColor="#79AD01" stopOpacity="1" />
          </linearGradient>
          <clipPath id="clip0_5_55">
            <rect
              x="2.80078"
              y="2.80078"
              width="50.4"
              height="50.4"
              rx="25.2"
              fill="white"
            />
          </clipPath>
          <clipPath id="clip1_5_55">
            <rect
              x="2.79883"
              y="2.80078"
              width="50.4"
              height="50.4"
              rx="25.2"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>
    )
  }
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}>
      <circle cx="28" cy="28" r="28" fill="#F4F4F4" />
      <g clipPath="url(#clip0_5_55)">
        <circle cx="28.0008" cy="28.0008" r="25.2" fill="#EEEEEE" />
        <g filter="url(#filter0_d_5_55)">
          <rect
            x="9.80078"
            y="16.8008"
            width="37.1"
            height="37.8"
            rx="2.1"
            fill="white"
          />
          <rect
            x="10.1508"
            y="17.1508"
            width="36.4"
            height="37.1"
            rx="1.75"
            stroke="url(#paint0_linear_5_55)"
            strokeWidth="0.7"
          />
        </g>
        <path
          d="M40.6008 29.8204L37.8008 30.8004V25.9004H43.4008V30.8004L40.6008 29.8204Z"
          fill="#F0F0F0"
        />
        <rect
          x="11.9004"
          y="34.3008"
          width="2.8"
          height="2.8"
          rx="0.7"
          fill="#E1E1E1"
        />
        <rect
          x="11.9004"
          y="21.7012"
          width="19.6"
          height="2.8"
          rx="0.7"
          fill="#E1E1E1"
        />
        <rect
          x="11.9004"
          y="25.9004"
          width="13.3"
          height="2.8"
          rx="0.7"
          fill="#E1E1E1"
        />
        <rect
          x="16.8008"
          y="34.3008"
          width="24.5"
          height="2.8"
          rx="0.7"
          fill="#F0F0F0"
        />
        <rect
          x="16.8008"
          y="38.5"
          width="13.3"
          height="2.8"
          rx="0.7"
          fill="#F0F0F0"
        />
        <rect
          x="16.8008"
          y="42.7012"
          width="20.3"
          height="2.8"
          rx="0.7"
          fill="#F0F0F0"
        />
        <rect
          x="11.9004"
          y="38.5"
          width="2.8"
          height="2.8"
          rx="0.7"
          fill="#E1E1E1"
        />
        <rect
          x="11.9004"
          y="42.7012"
          width="2.8"
          height="2.8"
          rx="0.7"
          fill="#E1E1E1"
        />
        <g filter="url(#filter1_d_5_55)">
          <circle cx="40.6004" cy="23.1004" r="4.2" fill="#F0F0F0" />
        </g>
        <circle cx="40.6008" cy="23.1008" r="2.8" fill="#E1E1E1" />
      </g>
      <rect
        x="3.15078"
        y="3.15078"
        width="49.7"
        height="49.7"
        rx="24.85"
        stroke="url(#paint1_linear_5_55)"
        strokeWidth="0.7"
      />
      <circle cx="28" cy="28" r="27.5" fill="white" stroke="#F1F1F1" />
      <g clipPath="url(#clip1_5_55)">
        <circle
          cx="27.9988"
          cy="28.0008"
          r="25.2"
          fill="#90CE00"
          fillOpacity="0.05"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.1632 15.8545C28.3813 21.1828 23.4323 25.4605 16.8449 28.2307C16.1881 28.5069 15.6484 28.7122 14.2254 29.2268L14 29.3083L14.011 29.5682C14.0321 30.0682 13.8268 30.0123 15.746 30.0401C16.7488 30.0547 17.7344 30.0934 18.1919 30.1363C21.8359 30.4782 26.0212 31.573 29.5506 33.1077C30.3966 33.4755 32.2286 34.3897 33.0663 34.8621C35.4039 36.18 37.8486 37.8773 39.9693 39.6545C40.3148 39.9441 40.4073 40 40.5409 40C40.675 40 40.7266 39.9685 40.865 39.8025C40.9684 39.6783 41.0167 39.5836 40.9948 39.5476C40.9757 39.5161 40.8049 39.3008 40.6152 39.0692C40.4255 38.8377 40.0269 38.3291 39.7293 37.939C34.8612 31.559 32.388 25.2438 32.1961 18.7034C32.1686 17.7674 32.192 15.9236 32.2386 15.3577C32.2546 15.1646 32.2505 15.1574 32.0972 15.1088C32.0105 15.0813 31.8652 15.0455 31.7744 15.0294L31.6094 15L31.1632 15.8545Z"
          fill="#90CE00"
        />
      </g>
      <rect
        x="3.14883"
        y="3.15078"
        width="49.7"
        height="49.7"
        rx="24.85"
        stroke="url(#paint2_linear_5_55)"
        strokeWidth="0.7"
      />
      <defs>
        <filter
          id="filter0_d_5_55"
          x="6.30078"
          y="16.1008"
          width="44.0996"
          height="44.8008"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2.8" />
          <feGaussianBlur stdDeviation="1.75" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_5_55"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_5_55"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_5_55"
          x="33.6004"
          y="17.5004"
          width="14.0004"
          height="14.0004"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.4" />
          <feGaussianBlur stdDeviation="1.4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_5_55"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_5_55"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_5_55"
          x1="28.3508"
          y1="16.8008"
          x2="28.3508"
          y2="54.6008"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="1" />
          <stop offset="1" stopColor="white" stopOpacity="1" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5_55"
          x1="28.0008"
          y1="2.80078"
          x2="28.0008"
          y2="53.2008"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#DADADA" />
          <stop offset="1" stopColor="#CDCDCD" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_5_55"
          x1="27.9988"
          y1="2.80078"
          x2="27.9988"
          y2="53.2008"
          gradientUnits="userSpaceOnUse">
          <stop stopOpacity="1" />
          <stop offset="1" stopOpacity="1" />
        </linearGradient>
        <clipPath id="clip0_5_55">
          <rect
            x="2.80078"
            y="2.80078"
            width="50.4"
            height="50.4"
            rx="25.2"
            fill="white"
          />
        </clipPath>
        <clipPath id="clip1_5_55">
          <rect
            x="2.79883"
            y="2.80078"
            width="50.4"
            height="50.4"
            rx="25.2"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export const BomberoAena = styled(Svg)
